/** @jsxImportSource @emotion/react */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { useFormContext } from "react-hook-form";
import "twin.macro";
import {
  ErrorMessage as CustomErrorMessage,
  CheckBoxStyled,
  FormGroup,
  InputLabel,
  InputStyled,
  Label,
  RequiredAsterisk,
} from "../shared/components/Form";
import { UnavailabilityList } from "../shared/components/UnavailabilityList";
import { CurrentSiteContext } from "../shared/contexts/CurrentSiteContext";
import { formatDate } from "../shared/functions/Datetime";
import { hasAnyPermission } from "../shared/functions/RoleManagement";
import { useConnectedUser } from "../shared/hooks/useConnectedUser";
import { useStartEndDates } from "../shared/hooks/useStartEndDates";
import { DrivingLicense, drivingLicensePriorities } from "../types/DrivingLicense";
import { Person } from "../types/Person";
import { Permission, Role } from "../types/Role";
import { StandardStatus } from "../types/StandardStatus";
import {
  PersonUnavailabilityType,
  Unavailability,
  UnavailabilityType,
} from "../types/Unavailability";
import { ExclamationIcon, XIcon } from "@heroicons/react/outline";
import tw from "twin.macro";

export const PersonForm = (props) => {
  const { connectedPerson } = useConnectedUser();
  const { currentSite } = useContext(CurrentSiteContext);
  const {
    register,
    resetField,
    formState: { errors },
    watch,
    getValues,
    setValue,
  } = useFormContext();

  const absences: Unavailability[] = watch("absences");
  const setAbsences = useCallback((newAbsences) => setValue("absences", newAbsences), [setValue]);

  const {
    startDate,
    endDate,
    onStartDateChange,
    onEndDateChange,
    errorMessage: contractDatesError,
  } = useStartEndDates(getValues("contractStart"), getValues("contractEnd"));
  const [licenceExpiryDate, setLicenceExpiryDate] = useState(getValues("licenceExpiryDate"));

  useEffect(() => {
    setValue("contractStart", startDate);
  }, [startDate, setValue]);
  useEffect(() => {
    setValue("contractEnd", endDate);
  }, [endDate, setValue]);
  useEffect(() => {
    setValue("site", currentSite.name ?? "");
  }, [connectedPerson, setValue]);
  useEffect(() => {
    setValue("licenceExpiryDate", licenceExpiryDate);
  }, [licenceExpiryDate, setValue]);

  const personUnavailabilityTypes = useMemo<UnavailabilityType[]>(
    () =>
      Object.values(PersonUnavailabilityType).filter((t) => t !== PersonUnavailabilityType.CONTRAT),
    []
  );

  const { permissions } = useConnectedUser();
  const filteredRoles = [];
  if (hasAnyPermission([Permission.MANAGE_TECHNICIANS], permissions)) {
    filteredRoles.push(Role.TECHNICIAN);
  }
  if (hasAnyPermission([Permission.MANAGE_PLANNERS], permissions)) {
    filteredRoles.push(Role.PLANNER);
    filteredRoles.push(Role.SALESMAN);
  }
  if (hasAnyPermission([Permission.MANAGE_SITE_ADMINISTRATORS], permissions)) {
    filteredRoles.push(Role.SITE_MANAGER, Role.ADMIN);
  }

  const [licences, setLicences] = useState(getValues("drivingLicenses"));
  const hasPLorSPL =
    licences?.includes(DrivingLicense.PL) || licences?.includes(DrivingLicense.SPL);

  const onChangeLicences = (e) => {
    const l = new Set(licences);
    if (e.target.checked) {
      l.add(e.target.value);
      if (e.target.value === DrivingLicense.NOLICENCE) {
        l.clear();
        l.add(DrivingLicense.NOLICENCE);
      } else {
        l.delete(DrivingLicense.NOLICENCE);
      }
      const priority = drivingLicensePriorities.get(e.target.value);
      if (priority >= 0) {
        const priority = drivingLicensePriorities.get(e.target.value);
        drivingLicensePriorities.forEach((v, k) => v >= priority && v >= 0 && l.add(k));
      }
    } else {
      const priority = drivingLicensePriorities.get(e.target.value);
      if (priority >= 0) {
        drivingLicensePriorities.forEach((v, k) => v <= priority && v >= 0 && l.delete(k));
      }
      l.delete(e.target.value);
    }
    setLicences(Array.from(l));
    resetField("drivingLicenses", { defaultValue: false });

    //Remove licenceExpiryDate if has no PL or SPL
    if (
      !Array.from(l)?.includes(DrivingLicense.PL) ||
      !Array.from(l)?.includes(DrivingLicense.SPL)
    ) {
      setLicenceExpiryDate("");
      setValue("licenceExpiryDate", "");
    }
  };

  const handleChangeLicenceExpiryDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLicenceExpiryDate(event.target.value);
  };

  const parsedDate = new Date(licenceExpiryDate);

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  // Calculate two months from today
  const twoMonthsFromToday = new Date(today);
  twoMonthsFromToday.setMonth(today.getMonth() + 2);

  return (
    <div tw="mt-6" {...props}>
      <div tw="grid grid-cols-2 gap-6 mt-6">
        <FormGroup>
          <Label htmlFor="person-name">
            Nom
            <RequiredAsterisk tw="text-red-500" />
          </Label>
          <InputStyled
            type="text"
            {...register("name", { required: "Champs obligatoire" })}
            id="person-name"
          />
          <ErrorMessage as={CustomErrorMessage} name="name" errors={errors} />
        </FormGroup>

        <FormGroup>
          <Label htmlFor="person-shortname">
            Prénom
            <RequiredAsterisk tw="text-red-500" />
          </Label>
          <InputStyled
            type="text"
            {...register("shortName", { required: "Champs obligatoire" })}
            id="person-shortname"
          />
          <ErrorMessage as={CustomErrorMessage} name="shortName" errors={errors} />
        </FormGroup>
      </div>

      <div tw="grid grid-cols-2 gap-6 mt-6">
        <FormGroup>
          <Label htmlFor="person-phone">Téléphone</Label>
          <InputStyled type="tel" {...register("phone")} id="person-phone" />
          <ErrorMessage as={CustomErrorMessage} name="phone" errors={errors} />
        </FormGroup>

        <FormGroup>
          <Label htmlFor="person-site">Site</Label>
          <InputStyled
            type="text"
            {...register("site", { required: true, setValueAs: () => currentSite })}
            readOnly
            id="person-site"
            tw="bg-gray-50 text-gray-500"
          />
        </FormGroup>
      </div>

      <FormGroup>
        <InputLabel label="Intérimaire" inputId="person-tempWorker" tw="mt-0">
          <CheckBoxStyled {...register("tempWorker")} id="person-tempWorker" type="checkbox" />
        </InputLabel>
      </FormGroup>

      <div tw="grid grid-cols-2 gap-6 mt-6">
        <FormGroup>
          <Label htmlFor="">
            Permis
            <RequiredAsterisk tw="text-red-500" />
            <ErrorMessage as={CustomErrorMessage} name="drivingLicenses" errors={errors} />
          </Label>
          {Object.entries(DrivingLicense).map(([, value]) => (
            <div key={value} tw="flex space-x-2 items-center">
              <CheckBoxStyled
                type="checkbox"
                value={value}
                {...register("drivingLicenses", { required: "Champs obligatoire" })}
                onChange={onChangeLicences}
                checked={licences?.find((v) => v == value)}
                id={value}
              />
              <Label
                css={[
                  (value === "PL" || value === "SPL") &&
                    (parsedDate >= today && parsedDate <= twoMonthsFromToday
                      ? tw`text-orange-600`
                      : parsedDate < today
                      ? tw`text-red-600`
                      : tw`text-gray-700`),
                  tw`flex items-center gap-2 text-sm font-medium  cursor-pointer`,
                ]}
                htmlFor={value}>
                {value}
                {(value === "PL" || value === "SPL") &&
                  (parsedDate >= today && parsedDate <= twoMonthsFromToday ? (
                    <ExclamationIcon tw="h-5 text-orange-500" />
                  ) : parsedDate < today ? (
                    <XIcon tw="h-5 text-red-500" />
                  ) : (
                    ""
                  ))}
              </Label>
            </div>
          ))}
        </FormGroup>

        <FormGroup>
          <Label htmlFor="person-roles">Rôles</Label>
          {filteredRoles.map((value) => (
            <div key={value} tw="flex space-x-2 items-center">
              <CheckBoxStyled type="checkbox" value={value} {...register("roles")} id={value} />
              <Label tw="text-sm font-medium text-gray-700 cursor-pointer" htmlFor={value}>
                {value}
              </Label>
            </div>
          ))}
        </FormGroup>
      </div>

      {hasPLorSPL && (
        <div tw="grid grid-cols-2 gap-6 mt-6">
          <FormGroup>
            <Label
              htmlFor="person-licenceExpiryDate"
              css={[
                parsedDate >= today && parsedDate <= twoMonthsFromToday
                  ? tw`text-orange-600`
                  : parsedDate < today
                  ? tw`text-red-600`
                  : tw`text-gray-700`,
                tw`flex items-center gap-2 text-sm font-medium  cursor-pointer`,
              ]}>
              Échéance permis PL ou SPL
            </Label>
            <InputStyled
              type="date"
              value={
                licenceExpiryDate ? new Date(licenceExpiryDate).toISOString().slice(0, 10) : ""
              }
              onChange={handleChangeLicenceExpiryDate}
              id="person-licenceExpiryDate"
            />
            <ErrorMessage as={CustomErrorMessage} name="licenceExpiryDate" errors={errors} />
          </FormGroup>
        </div>
      )}

      <div tw="grid grid-cols-2 gap-6 mt-6">
        <FormGroup>
          <Label htmlFor="">Dates de contrat (inclus)</Label>
          <section tw="flex flex-row space-x-2 mb-2 items-center">
            <span tw="text-sm font-medium text-gray-700">Du</span>
            <InputStyled
              type="date"
              value={startDate || ""}
              onChange={onStartDateChange}
              tw="!w-40"
            />
            <span tw="text-sm font-medium text-gray-700">au</span>
            <InputStyled type="date" value={endDate || ""} onChange={onEndDateChange} tw="!w-40" />
          </section>

          <CustomErrorMessage>{contractDatesError}</CustomErrorMessage>
        </FormGroup>
      </div>

      <div tw="grid [grid-template-columns: repeat(5,minmax(0, 0.5fr))] gap-6 mt-6 border-t border-gray-200">
        <FormGroup tw="mt-6">
          <Label htmlFor="person-login">Login</Label>
          <InputStyled type="text" {...register("login")} id="person-login" />
          <ErrorMessage as={CustomErrorMessage} name="login" errors={errors} />
        </FormGroup>

        <FormGroup tw="mt-6">
          <Label htmlFor="person-initials">Initiales</Label>
          <InputStyled type="text" {...register("initials")} id="person-initials" />
          <ErrorMessage as={CustomErrorMessage} name="initials" errors={errors} />
        </FormGroup>
      </div>

      <FormGroup tw="border-t border-gray-200 mt-6">
        <Label htmlFor="" tw="mt-6">
          Absences (inclus)
        </Label>
        <UnavailabilityList
          types={personUnavailabilityTypes}
          unavailabilities={absences}
          setUnavailabilities={setAbsences}
        />
      </FormGroup>
    </div>
  );
};

export const personToFormValues = (person: Person) => {
  const contractDates = person?.unavailabilities?.find(
    (u) => u.type === PersonUnavailabilityType.CONTRAT
  );
  return {
    ...person,
    status: person.status ? person.status : StandardStatus.active,
    contractStart: contractDates?.start && formatDate(contractDates?.start, "YYYY-MM-DD"),
    contractEnd: contractDates?.end && formatDate(contractDates?.end, "YYYY-MM-DD"),
    absences: person.unavailabilities?.filter((u) => u.type !== PersonUnavailabilityType.CONTRAT),
    // We must set Technician as default role, unless checkbox will not work when it's the only option available
    roles: !person.roles || person.roles.length === 0 ? [Role.TECHNICIAN] : person.roles,
  };
};

export const formValuesToPerson = (formValues): Person => {
  const newUnavailabilities = formValues.absences ?? [];
  if (formValues.contractStart || formValues.contractEnd) {
    const contractUnavailability: Unavailability = {
      start: formValues.contractStart,
      end: formValues.contractEnd,
      type: PersonUnavailabilityType.CONTRAT,
    };
    newUnavailabilities.push(contractUnavailability);
  }
  return {
    ...formValues,
    unavailabilities: newUnavailabilities,
    drivingLicenses: formValues.drivingLicenses === false ? [] : formValues.drivingLicenses,
    roles: formValues.roles === false ? [] : formValues.roles,
    // This attribute doesn't exist in the backend : contract dates are in the availabilities list
    contractStart: undefined,
    contractEnd: undefined,
  };
};
